
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data"],
  setup(props, { emit }) {
    const brand: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.BRAND_LIST);
    const brandList = computed(() => store.state.BrandModule.brand.value || []);

    const onChange = (args) => {
      emit("changeBrand", args);
    };
    return { close, brand, store, brandList, onChange };
  },
};
