
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Image from "primevue/image";
import Dialog from "primevue/dialog";

export default {
  props: ["fileTitle", "createdAt", "fileType", "fileUrl"],
  setup(props, { emit }) {
    const displayBasic = ref(false);
    const removes = (args) => {
      emit("removeFile", "ali");
    };
    const openBasic = () => {
      displayBasic.value = true;
    };
    return { props, removes, openBasic, displayBasic };
  },
};
