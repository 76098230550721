
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data"],
  setup(props, { emit }) {
    const shippingType: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.SHIPPINGTYPE_LIST);
    const shippigTypeList = computed(
      () => store.state.ShippingTypeModule.shippingType.value || []
    );

    const onChange = (args) => {
      emit("changeShippingType", args);
    };
    return { close, shippingType, store, shippigTypeList, onChange };
  },
};
