
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data"],
  setup(props, { emit }) {
    const orderType: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.ORDERTYPE_LIST);
    const orderTypeList = computed(
      () => store.state.OrderTypeModule.orderType.value || []
    );

    const onChange = (args) => {
      emit("changeOrderType", args);
    };
    return { close, orderType, store, orderTypeList, onChange };
  },
};
